import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
 
const SimpleSwiperWithParams = () => {
  const params = {
    slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
  }
 
  return(
    <Swiper {...params}>
      <div><img alt="An example UK scene" src="https://images.unsplash.com/photo-1569013264326-aa879a5737e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60" /></div>
      <div><img alt="An example UK scene" src="https://images.unsplash.com/photo-1569013264326-aa879a5737e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60" /></div>
      <div>Slide 3</div>
      <div>Slide 4</div>
      <div>Slide 5</div>
    </Swiper>
  )
}
 
export default SimpleSwiperWithParams;